import React, { createContext, useState, useEffect } from "react";
import { useLocale } from "../hooks/useLocale";

import {
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth"

import {
  onSnapshot,
  query,
  where,
  collectionGroup,
} from "firebase/firestore"

// Lazy load Firebase and related imports
const lazyFirebase = () => import("../utils/firebase");
const lazyFirebaseAuth = () => import("firebase/auth");
// const lazyFirestore = () => import("firebase/firestore");

export const AppContext = createContext({
  user: null,
  db: null,
  auth: null,
});

const AppProvider = ({ children }) => {
  const databaseName = "brent";
  const isBrowser = typeof window !== "undefined";
  const [auth, setAuth] = useState(null);
  const [db, setDb] = useState(null);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false)


  const [selectedIndex, setSelectedIndex] = useState("root");
  const [isMobile, setIsMobile] = useState(isBrowser ? window.innerWidth < 480 : undefined);
  const [isTablet, setIsTablet] = useState(isBrowser ? window.innerWidth < 787 : undefined);
  const { locale } = useLocale();
  
  const [fadeOut, setFadeOut] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);




  const handleClosePopup = (reason) => reason !== "clickaway"
    ? setIsPopupOpen({ open: false, message: "none", severity: "success" })
    : null;

  // Lazy load Firebase when necessary
  useEffect(() => {
    const initializeFirebase = async () => {
      const { firebase, authTest, database } = await lazyFirebase();
      setAuth(authTest);
      setDb(database);
      setIsFirebaseInitialized(true);
    };

    initializeFirebase();
  }, []);

  useEffect(() => {
    if (!auth || !isFirebaseInitialized) return;

    // Real-time listeners
    if (user) {
      // const userRef = collection(db, databaseName);
      const q = query(
        collectionGroup(db, databaseName),
        where("email", "==", user ? user.email : "")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            if (JSON.stringify(change.doc.data()) !== JSON.stringify(userData)) {
              unsubscribe();
            }
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
        querySnapshot.forEach((doc) => {
          setUserData(doc.data());
        });
      });

      return () => {
        unsubscribe();
        console.log("unsubscribed!");
      };
    }
  }, [databaseName, user, isFirebaseInitialized]);

  const checkAuth = async () => {
    if (!auth) return;
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      return idToken;
    } catch (error) {
      console.log("Authentication issues: ", error);
      setIsPopupOpen({
        open: true,
        message: "Authentication issues: " + error.message,
        severity: "error",
        closePopup: handleClosePopup,
      });
    }
  };

  // State for Alert Popup
  const [isPopupOpen, setIsPopupOpen] = useState({
    open: false,
    message: "",
    severity: "",
    closePopup: handleClosePopup,
  });

  useEffect(() => {
    if (!auth) return;
    return auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        console.log("user is logged in");
        setUser(firebaseUser);
      } else {
        console.log("user is not logged in");
      }
    });
  }, [auth]);

  const handleSignOut = () => {
    if (!auth) return;
    signOut(auth)
      .then(() => {
        setIsPopupOpen({
          open: true,
          message: "Signed out...",
          severity: "success",
          closePopup: handleClosePopup,
        });
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign-out error: ", error);
      });
  };

  const handleSignIn = async (email, password) => {
    if (!auth) {
      // Load auth module dynamically if needed
      await lazyFirebaseAuth();
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setIsPopupOpen({
          open: true,
          message: "Signed in with email " + userCredential.user.email,
          severity: "success",
          closePopup: handleClosePopup,
        });
        setUser(userCredential.user);
      })
      .catch((error) => {
        setIsPopupOpen({
          open: true,
          message: error.message,
          severity: "error",
          closePopup: handleClosePopup,
        });
        console.log(error.message);
      });
  };

  // Check for mobile size
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 787) {

      }
      const isTabletView = window.innerWidth < 787;
      const isMobileView = window.innerWidth < 480;
      setIsMobile(isMobileView);
      setIsTablet(isTabletView);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const output = {
    user,
    db,
    auth,
    handleSignOut,
    handleSignIn,
    isPopupOpen,
    setIsPopupOpen,
    isBrowser,
    handleClosePopup,
    userData,
    isLoggedIn: () => !!user,
    selectedIndex,
    setSelectedIndex,
    checkAuth,
    isMobile,
    isTablet,
    locale,
    mobileOpen,
    setMobileOpen,
    fadeOut,
    setFadeOut,
    activeIndex,
    setActiveIndex
  };

  // if (!isFirebaseInitialized) {
  //   return <div>Loading...</div>; // or a loading spinner
  // }

  return <AppContext.Provider value={output}>{children}</AppContext.Provider>;
};

export default AppProvider;