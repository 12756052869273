import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { useLocale } from "../../hooks/useLocale";
import { getLocalizedLink } from "../../utils/getLocalizedLink";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material";
import { AppContext } from "../../context/appContext";
import useTranslation from "../../hooks/useTranslation";


const MenuItem = ({ item }) => {
  const theme = useTheme();
  const { locale } = useLocale();
  const { translations } = useTranslation();
  const { selectedIndex, setSelectedIndex, setMobileOpen, mobileOpen } = useContext(AppContext);

  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  
  const toggleExpand = () => {
    setIsVisible(prev => !prev);
  };

  const checkItemForPageChild = (item, currentPath) => {
    const localizedPath = getLocalizedLink(locale, `/rental${item.urlPath}/`);
    if (localizedPath === currentPath) {
      setIsVisible(true);
    } else if (item.children?.length > 0) {
      item.children.forEach(i => {
        checkItemForPageChild(i, currentPath);
      });
    }
  };

  const handleParentClick = (e, item) => {
    setSelectedIndex(item.id);
    const targetPath = getLocalizedLink(locale, `/rental${item.urlPath}/`);

    if (targetPath === decodeURI(location.pathname)) {
      toggleExpand();
    } else {
      navigate(targetPath);
      console.log(item.children)
      if (!item.children || item.children.length === 0) {
        setMobileOpen(!mobileOpen);
        // Close the menu if the item has no children
      }
    }
  };

  useEffect(() => {
    checkItemForPageChild(item, decodeURI(location.pathname));
  }, [location.pathname, item]);

  const listItemStyles = {
    alignItems: "flex-end",
    borderBottom: "1px solid #dbdbdb",
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.default.selected,
    },
    "&& .MuiTouchRipple-child": {
      backgroundColor: theme.palette.secondary.main,
    },
    ":hover": {
      backgroundColor: "white",
      color: `${theme.palette.secondary.main} !important`,
    },
  };

  return (
    <li style={{ listStyleType: "none", margin: 0, padding: 0 }}>
      {item.children ? (
        <>
          <ListItemButton
            aria-expanded={isVisible}
            aria-controls={`submenu-${item.id}`}
            color="secondary"
            sx={listItemStyles}
            onClick={e => handleParentClick(e, item)}
          >
            <ListItemText
              sx={{ marginTop: "0px", marginBottom: "0px" }}
              primary={translations[item.title]}
            />
            {isVisible ? (
              <ExpandLess
                sx={{ width: "0.9em !important", height: "0.9em" }}
                className="expand-icon"
                color="secondary"
              />
            ) : (
              <ExpandMore
                sx={{ width: "0.9em !important", height: "0.9em" }}
                className="expand-icon"
                color="secondary"
              />
            )}
          </ListItemButton>

          <Collapse
            sx={{ paddingLeft: "0.5rem" }}
            in={isVisible}
            timeout="auto"
            unmountOnExit
          >
            <ul style={{ listStyleType: "none", paddingLeft: "0", margin: 0 }}>
              {item.children.map(child => (
                <li key={child.id} style={{ paddingLeft: 10 }}>
                  <MenuItem item={child} />
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <ListItemButton
          color="secondary"
          sx={listItemStyles}
          onClick={e => handleParentClick(e, item)}
        >
          <ListItemText
            sx={{ marginTop: "0px", marginBottom: "0px" }}
            primary={locale === "en" ? item.title : translations[item.title]}
          />
        </ListItemButton>
      )}
    </li>
  );
};

export default MenuItem;