export const getLocalizedLink = (locale, path) => {
  // Normalize path to start with a "/"
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;

  // Check if the locale is "no"
  if (locale === 'no') {
    // Add "/no" prefix for "no" locale
    return `/no${normalizedPath}`;
  } else {
    // No prefix for "en" locale
    return normalizedPath;
  }
};