import React, { useEffect, Suspense } from "react"
// import { Script } from "gatsby"
import { Helmet } from "react-helmet"
import ResponsiveDrawer from "../drawer/drawer"
import { useLocale } from "../../hooks/useLocale"
import Loading from "../loading/loading"
import { SpeedInsights } from "@vercel/speed-insights/react"
import Header from "../header/header"
import HeaderMobile from "../headerMobile/headerMobile"
import Hamburger from "../hamburger/hamburger"

const logoImg = "../../images/brent_logo_orange_print.svg"

const Layout = ({ pageResources, children, mobile, location, pageContext }) => {
  const { changeLocale, mobileOpen } = useLocale()
  const locale = pageContext.locale
  // const [mobileOpen, setMobileOpen] = React.useState(false)


  // Fixing the viewport height
  // useEffect(() => {
  //   // On load and resize, calculate the new height and set it in the CSS variable
  //   if (typeof window !== "undefined") {
  //     const updateViewportHeight = () => {
  //       document.documentElement.style.setProperty(
  //         "--actual-vh",
  //         `${window.innerHeight * 0.01}px`,
  //       )
  //     }

  //     // Set initial height
  //     updateViewportHeight()

  //     // Update on resize
  //     window.addEventListener("resize", updateViewportHeight)

  //     // Cleanup event listener on unmount
  //     return () => {
  //       window.removeEventListener("resize", updateViewportHeight)
  //     }
  //   }
  // }, []) // Empty dependency array ensures this only runs once when the component mounts

  // useEffect(() => {
  //   console.log(locale)
  //   changeLocale(locale);
  // }, [locale]);

  const [open, setOpen] = React.useState()
  const [isNonexistent, setIsNonexistent] = React.useState(true)

  // console.log("FUCK",location)
  // console.log("FUCK",pageResources?.page.path)

  // THINGS TO UPDATE FOR FULLSCREEN PATHS AND 404 PAGES:
  useEffect(() => {
    if (pageResources?.page.path === "/en/404/") {
      setIsNonexistent(true)
      console.log("TRUE!!!!!")
    } else if (pageResources?.page.path === "/no/404/") {
      setIsNonexistent(true)
      console.log("TRUE!!!!!")
    } else if (pageResources?.page.path === "/404.html") {
      setIsNonexistent(true)
      console.log("TRUE!!!!!")
    } else if (pageResources?.page.path === "/404/") {
      setIsNonexistent(true)
      console.log("TRUE!!!!!")
    } else if (pageResources?.page.path === "/dev-404-page/") {
      setIsNonexistent(true)
      console.log("TRUE!!!!!")
    } else {
      setIsNonexistent(false)
    }
  })

  // Your array with specific paths
  const fullScreenPaths = [
    // "/en/",
    "/",
    "/no/",
    "/404/",
    "/dev-404-page/",
    "/no/404/",
    "/terms/",
    "/no/terms/",
    "/contact/",
    "/no/contact/",
    "/support/",
    "/no/support/",
    "/support/manuals/",
    "/no/support/manuals/",
    "/support/report/",
    "/no/support/report/",
    "/no/productions/",
    "/productions/",
  ]

  // Your new pattern paths
  const patternPaths = [
    "/productions/*",
    // Add more patterns here if needed
  ]

  const matchesPath = (pathname, paths, patterns) => {
    // Check for exact match
    if (paths.includes(pathname)) {
      return true
    }

    // Check for pattern matches
    return patterns.some(pattern => {
      // Convert the pattern to a RegExp
      const regex = new RegExp(`^${pattern.replace("*", ".*")}$`)
      return regex.test(pathname)
    })
  }



  return (
    <React.Fragment>
      <SpeedInsights />
      <Helmet
        title={"Brent rental"}
        meta={[
          { name: "description", content: "A rental house in Oslo" },
          { name: "keywords", content: "rental, camera, video, equipment" },
        ]}
      >
        <link rel="preload" href={logoImg} as="image" />
      </Helmet>
      
      {matchesPath(location.pathname, fullScreenPaths, patternPaths) ? (
        <div>
          {/* <div style={{ marginBottom: "70px" }}> */}
          {/* <Header location={location}> </Header> */}
          {/* </div> */}
          {children}
          <HeaderMobile location={location} />
        </div>
      ) : isNonexistent ? (
        <div>
          {/* <div style={{ marginBottom: "70px" }}> */}
            <Header location={location}> </Header>
          {/* </div> */}
          {children}
          <HeaderMobile />
        </div>
      ) : (
        <div style={{ backgroundColor: "white" }}>
          <Header
            //  mobileOpen={mobileOpen}
            //  setMobileOpen={setMobileOpen}
            location={location}
          ></Header>
          <ResponsiveDrawer
            // setMobileOpen={setMobileOpen}
            // mobileOpen={mobileOpen}
            content={children}
            location={location}
            is404={isNonexistent}
          >
            {children}
          </ResponsiveDrawer>
          {/* <Hamburger /> */}

        </div>
      )}
      {/* <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-32W1T09F3W"
      ></Script>
      <Script id="second-unique-id">{`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-32W1T09F3W');`}</Script>

      <Script
        id="G-32W1T09F3W"
        dangerouslySetInnerHTML={{
          __html: `  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-32W1T09F3W');`,
        }}
      /> */}
    </React.Fragment>
  )
}

export default Layout
