import { createTheme } from "@mui/material"
const theme = createTheme({
  palette: {
    type: "light",
    // palette values for dark mode
    // divider: deepOrange[700],
    background: {
      default: {
        main: "#171E22",
        selected: "#f2f2f2",

        contrastText: "#ffff",
      },
      paper: {
        main: "#504f4f",
        contrastText: "#fff",
      },
    },
    text: {
      primary: "#fff",
      secondary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#eb5930",
    },
  },
  typography: {
    fontFamily: `"DM Sans", "Helvetica", "Arial", sans-serif`,
    allVariants: {
      color: "#000000",
    },
    h1: {
      margin: ".5em 0",
      fontWeight: "100",
      fontSize: "3em",
    },
    h2: {
      margin: ".5em 0",
      fontWeight: "200",
      fontSize: "2.5em",
    },
    h3: {
      margin: ".5em 0",
      fontSize: "2em",
    },
    h4: {
      margin: ".5em 0",
      fontSize: "1.5em",
    },
  },
})

export default theme
