import React from "react";
import { useHits } from "react-instantsearch-hooks-web";
import { Button } from "@mui/material";
import { Highlight } from "react-instantsearch-hooks-web";
import { useLocale } from "../../hooks/useLocale";
import { navigate } from "gatsby";
import * as searchStyles from "./search.module.css";
import { getLocalizedLink } from "../../utils/getLocalizedLink";
import { AppContext } from "../../context/appContext"; // Import AppContext


function CustomHits({ closeSearchResults, clearSearchInput }) {
  const { locale } = useLocale();
  const { hits } = useHits();
  const { setMobileOpen } = React.useContext(AppContext); // Get setMobileOpen from context

  const getTitleAttribute = () => {
    return locale === "no" ? "custom.custom_13" : "custom.custom_10";
  };

  const handleClick = (e, hit) => {
    console.log(hit, "AKJWHD KJAHWD KJAW DKJ AWKDJ ");
    clearSearchInput(); // Clear the search input
    closeSearchResults(); // Close the search bar
    setMobileOpen(false)
    navigate(getLocalizedLink(locale, `/rental${hit.urlPath}`));
  };

  return (
    <ol className={searchStyles.aisHitsList}>
      {hits.map((hit) => (
        <li
          key={hit.objectID}
          className={searchStyles.aisHitsItem}
          onClick={(e) => handleClick(e, hit)}
          onAuxClick={(e) => handleClick(e, hit)}
        >
          <Button
            sx={{
              width: "100%",
              textAlign: "start",
              justifyContent: "flex-start",
              padding: 0,
              textTransform: "none",
              height: "100%",
              width: "100%",
              padding: "5px 5px",
            }}
          >
            <span className={searchStyles.searchHighlight}>
              <Highlight attribute={getTitleAttribute()} hit={hit} />
            </span>
          </Button>
        </li>
      ))}
    </ol>
  );
}

export default CustomHits;
