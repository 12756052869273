import React from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

function NoResultsBoundary({ children }) {
  const { results } = useInstantSearch();

  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        <NoResults />
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();

  if (Object.keys(indexUiState).length === 0) {
    return null;
  } else {
    return (
      <div>
        <p style={{ margin: "0", color: "#EB5931" }}>
          No results for <q>{indexUiState.query}</q>.
        </p>
      </div>
    );
  }
}

export default NoResultsBoundary;
