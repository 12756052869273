module.exports = {
  en: {
    path: '/',
    locale: 'English',
    default: true,
  },
  no: {
    path: '/no',
    locale: 'Norwegian',
  },
}