import brent from "../../images/brent_logo_orange_print.svg"
import * as React from "react"
import * as drawerStyles from "./drawer.module.css"

import { useContext } from "react"
import { navigate } from "gatsby"
import { AppContext } from "../../context/appContext"
import LocaleSelector from "../localeSelector/localeSelector"

import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Drawer from "@mui/material/Drawer"

import { ClickAwayListener } from "@mui/material"

import Menu from "../menu/menu"
import SearchBar from "../search/searchBar"
// import CartMenu from "../cart/cartMenu"
import SignInPopup from "../signInPopup/SignInPopup"
// import { Flex, View } from "@adobe/react-spectrum"
// import BrentButton from "../button/brentButton"
import HomeFooter from "../homeFooter/homeFooter"

const drawerWidth = 300

function ResponsiveDrawer(props, cameraQuery) {
  const { window, children, location } = props
  const {
    user,
    userData,
    handleSignOut,
    handleSignIn,
    setIsPopupOpen,
    mobileOpen,
    setMobileOpen,
  } = useContext(AppContext)
  const mainRef = React.useRef(null)
  const [menu, setMenu] = React.useState(false)

  const handleOutsideClick = event => {
    setMobileOpen(false) // Close the drawer if clicked outside
  }


  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // Disable scroll when mobile drawer is open
      if (mobileOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        // Re-enable scroll when drawer is closed
        document.body.style.overflow = 'initial'
      }
    }

    return () => {
      // Clean up the overflow style when the component unmounts
      document.body.style.overflow = 'initial'
    }
  }, [mobileOpen])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div
      className={drawerStyles.mainSidebar}
      style={{
        minWidth: "400px",
        height: "calc(100vh - 228px)",
        overflow: "scroll",
        position: "-webkit-sticky",
        position: "sticky",
        top: "85px",
        overflowX: "hidden",
      }}
    >
      <div>
        <Menu
          menu={menu}
          setMenu={setMenu}
        ></Menu>
      </div>
    </div>
  )
  const mobileDrawer = (
    <div
      className={drawerStyles.mobileSidebar}
    >
      {user && userData ? (
        <div style={{ marginLeft: "10px" }}>
          {/* 
          //TODO: Fix this if you need users
          <View marginStart="auto" UNSAFE_className={drawerStyles.loginMenuBar}>
            <Flex
              alignItems="center"
              direction="row"
              UNSAFE_style={{ gap: "5px" }}
            >
              <BrentButton
                variant="main"
                onPress={() => navigate("/user/home")}
              >
                {userData.firstName + " " + userData.lastName}
              </BrentButton>
              <BrentButton
                variant="main"
                staticColor="black"
                minWidth={100}
                onPress={e => handleSignOut()}
              >
                Sign Out
              </BrentButton>
              <CartMenu mobile={true}></CartMenu>
            </Flex>
          </View> */}
        </div>
      ) : (
        <div style={{ marginLeft: "10px" }}>
          {/* <SignInPopup
            closeMenu={handleDrawerToggle}
            handleSignIn={handleSignIn}
            isMobile={false}
          ></SignInPopup> */}
        </div>
      )}
      <div style={{ marginLeft: "10px" }}>
        <SearchBar
          handleDrawerToggle={handleDrawerToggle}
          isMobile={false}
        ></SearchBar>
      </div>

      <div>
        <Menu
          menu={menu}
          setMenu={setMenu}
        ></Menu>
        {/* <CartMenu mobile={true}></CartMenu> */}
      </div>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/* <ClickAwayListener onClickAway={handleOutsideClick}> */}
        <Drawer
          PaperProps={{ style: { maxWidth: "70%" } }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            onBackdropClick: handleOutsideClick,
            keepMounted: true, // Better open performance on mobile.
          }}
          disableScrollLock={true}

          sx={{
            display: "flex",

            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {/* Adding brent logo to top mobile bar */}
          <div className={drawerStyles.flexMenu}>
            <img
              className={drawerStyles.rentalLogo}
              onClick={() => navigate("/#intro")}
              src={brent}
              alt="brent logo"
            ></img>
          </div>
          {drawer}
          {mobileDrawer}
          <LocaleSelector
            isMobile={true}
            location={location}
            index={1}
          ></LocaleSelector>
        </Drawer>
        {/* </ClickAwayListener> */}
        <Box
          component="main"
          sx={{
            display: "flex",
            height: " calc(100% - 65px)",
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            maxWidth: "100%",
            // marginTop: "90px",
          }}
        >
          {/* <SearchBar isOpen={searchOpen} setOpen={setIsSearchOpen}></SearchBar> */}
          {/* <Toolbar className="mobile-toggle"> */}
          {/* <IconButton
                className={drawerStyles.mobileToggle}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton> */}
          {/* </Toolbar> */}
          {drawer}
          <div
            className={drawerStyles.seperator}
            style={{
              width: "1px",
              height: "100vh",
              borderLeft: "1px solid #dbdbdb",
            }}
          ></div>
          <div
          ref={mainRef}
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5rem",
              borderLeft: "1px solid #dbdbdb",
              marginTop: "75px",
            }}
            className={drawerStyles.rentalWrapper}
          >
            {children}

            {/* {children} */}
          </div>
        </Box>
      </Box>
      <HomeFooter location={location}></HomeFooter>
    </>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default ResponsiveDrawer
