import React, { useEffect } from "react";
import { useLocale } from "../../hooks/useLocale";
import LanguageIcon from "@mui/icons-material/Language";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { SvgIcon } from "@mui/material";

const LocaleSelector = ({
  location,
  isMobile = false,
  index = 1,
  variant = "default",
  style = {},
}) => {
  const { changeLocale, locale } = useLocale();
  const iconColor = variant === "default" ? null : "white";

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {}, [locale]);

  const handleClose = (event, lang) => {
    event.preventDefault();
    if (!lang) {
      setOpen(false);
      return;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    changeLocale(lang, location);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    event.preventDefault();
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const test = {
    position: isMobile ? "absolute" : "unset",
    bottom: isMobile ? "0" : "unset",
  };

  return (
    <>
      {/* <div className="reiel" style={test}>
        <Stack direction="row" spacing={2}>
          <div>
            <Button
              sx={style}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <LanguageIcon sx={{ color: iconColor }} />
              <div
                style={{ fontSize: "14px", paddingLeft: "5px", color: iconColor }}
              >
                {locale === "en" ? "EN" : "NO"}
              </div>
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom"
              transition
              disablePortal
              sx={{ backgroundColor: "white" }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          selected={locale === "no"}
                          onClick={(e) => handleClose(e, "no")}
                        >
                          <SvgIcon>
                            <svg
                              style={{ width: "18px", marginRight: "5px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              viewBox="0 0 22 16"
                            >
                              <rect width="22" height="16" fill="#ba0c2f" />
                              <path
                                d="M0,8h22M8,0v16"
                                stroke="#fff"
                                strokeWidth="4"
                              />
                              <path
                                d="M0,8h22M8,0v16"
                                stroke="#00205b"
                                strokeWidth="2"
                              />
                            </svg>
                          </SvgIcon>
                          Norwegian
                        </MenuItem>
                        <MenuItem
                          selected={locale === "en"}
                          onClick={(e) => handleClose(e, "en")}
                        >
                          <SvgIcon>
                            <svg
                              style={{ width: "18px", marginRight: "5px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 50 30"
                              width="1000"
                              height="600"
                            >
                              <clipPath id="t">
                                <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z" />
                              </clipPath>
                              <path d="M0,0v30h50v-30z" fill="#012169" />
                              <path
                                d="M0,0 50,30M50,0 0,30"
                                stroke="#fff"
                                strokeWidth="6"
                              />
                              <path
                                d="M0,0 50,30M50,0 0,30"
                                clipPath="url(#t)"
                                stroke="#C8102E"
                                strokeWidth="4"
                              />
                              <path
                                d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z"
                                fill="#C8102E"
                                stroke="#FFF"
                                strokeWidth="2"
                              />
                            </svg>
                          </SvgIcon>
                          English
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      </div> */}
    </>
  );
};

export default LocaleSelector;