import * as searchStyles from "./search.module.css";
import React from "react";
import { InstantSearch } from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch/lite";
import CustomSearchBox from "./customSearchBox";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (
      requests.every(({ params }) => params?.query?.length < 1) ||
      !requests[0].params.query
    ) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }
    return algoliaClient.search(requests);
  },
};

const SearchBar = ({ isMobile }) => {
  const classnames = isMobile
    ? `${searchStyles.mobileSearchBar} ${searchStyles.searchContainer}`
    : `${searchStyles.searchContainer}`;

  return (
    <div id={searchStyles.searchBoxId} className={classnames}>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      >
        <div className={searchStyles.SearchBox}>
          <CustomSearchBox />
        </div>
      </InstantSearch>
    </div>
  );
};

export default SearchBar;
