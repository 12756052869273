// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// normalize CSS across browsers
import "./src/utils/fonts"
import "./src/css/normalize.css"
// custom CSS styles
import "./src/css/style.css"
// import { Provider, lightTheme } from "@adobe/react-spectrum"
import { ThemeProvider } from "@mui/material/styles"
import { SpeedInsights } from "@vercel/speed-insights/react"

// import "firebase/auth"
import React from "react"
import Layout from "./src/components/layout/layout"
import AppProvider from "./src/context/appContext"
import CartProvider from "./src/context/cartContext"
import theme from "./src/utils/theme"
import { LocaleProvider } from "./src/hooks/useLocale"
import { CustomCookieConsent } from "./src/utils/cookieBanner"


// export const wrapRootElement = ({
//   element,
//   pageContext: { locale, isDefault },
// }) => {
//   return (
//     <LocaleProvider >
//       <Layout {...props} locale={locale} isDefault={isDefault}>{element}</Layout>
//     </LocaleProvider>
//   )
// }

// export const onClientEntry = () => {
//   window.addEventListener('load', () => {
//     if (window.location.pathname.match(/^\/(en|no)\/404/)) {
//       window.location.href = window.location.pathname.replace(/404\/?$/, '');
//     }
//   });
// };

export function wrapPageElement({ element, props, location }) {

  return (
    // <Provider theme={lightTheme} colorScheme="light">
      <ThemeProvider theme={theme}>
        <LocaleProvider>
          <AppProvider>
            <CartProvider>
              <Layout
                locale={props.pageContext.locale}
                isDefault={props.pageContext.isDefault}
                {...props}
              >
                {element}
                <CustomCookieConsent />
              </Layout>
            </CartProvider>
          </AppProvider>
        </LocaleProvider>
      </ThemeProvider>
    // </Provider>
  )
}
