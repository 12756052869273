import React, { useContext, useEffect, useState } from "react"
import {
  useScroll,
  useMotionValueEvent,
  motion,
  useTransform,
  useMotionValue,
  useSpring,
} from "framer-motion"

import { navigate } from "@reach/router"
import TestButton from "../button/testButton"
import { getLocalizedLink } from "../../utils/getLocalizedLink"
import brent from "../../images/brent_logo_orange_svg.svg"
import brentMobile from "../../images/brent_secondarylogo_orange_web.png"
import LocaleSelector from "../localeSelector/localeSelector"
import { AppContext } from "../../context/appContext"
import { useLocale } from "../../hooks/useLocale"

import "../../pages/index.css"
import SearchBar from "../search/searchBar"
import * as headerStyles from "./header.module.css"
import HeaderMobile from "../headerMobile/headerMobile"

const Header = ({
  location,
  children,
  navHeader,
  mobileOpen,
  setMobileOpen,
}) => {
  const {
    isMobile,
    isTablet,
    setFadeOut,
    activeIndex,
    setActiveIndex,
  } = useContext(AppContext)

  // const [fadeOut, setFadeOut] = React.useState(false)
  // const [activeIndex, setActiveIndex] = React.useState(0)

  const { locale } = useLocale()
  const [brentImage, setBrentImage] = useState(brent)
  useEffect(() => {
    if (isMobile) {
      setBrentImage(brentMobile)
    } else {
      setBrentImage(brent)
    }
  }, [isMobile])


  // let brentImage = isMobile ? brentMobile : brent

  // if (typeof window !== "undefined") {
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //       navigator.userAgent,
  //     )
  //   ) {
  //   }
  //   // true for mobile device
  //   console.log("mobile device")
  // } else {
  //   // false for not mobile device
  //   console.log("not mobile device")
  // }

  const imageNotFullscreen = e => {
    // Hide the image modal
    const container = document.querySelector("#fullscreen-image-container")
    container.classList.toggle("container-visible")
    document.body.style.overflow = "auto"
    // Remove the image
    const img = document.querySelector("#temp-image-id")
    img.remove()
  }

  //ADD TIMER TO SCROLL DOWN IF USER DOES NOT INTERACT WITH WEBSITE
  // React.useEffect(() => {
  //   if (location.pathname !== "/") return
  //   const timerScrollElement = document.querySelector(".scrollTarget")
  //   const yOffset = +300
  //   const y =
  //     timerScrollElement.getBoundingClientRect().top + window.scrollY + yOffset

  //   if ((typeof window !== "undefined") & (window.scrollY < 350)) {
  //     setTimeout(function () {
  //       if ((typeof window !== "undefined") & (window.scrollY < 350)) {
  //         console.log("auto scroll trigger running...")
  //         window.scrollTo({ top: y, behavior: "smooth" })
  //       }
  //     }, 2500)
  //   }
  // }, [])

  const amount = 1100 //360

  const ref = React.useRef(null)
  const { scrollY } = useScroll()

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  })

  const x = useMotionValue(0)
  useMotionValueEvent(scrollY, "change", latest => {
    x.set(latest)
    // console.log(latest)
  })

  const spring = useSpring(x, {
    stiffness: 1000,
    damping: 50,
    mass: 0.1,
    bounce: 0,
  })
  const imageOverlayColor = useTransform(
    x,
    [100, amount - 100],
    ["#232323", "#23232300"],
  )
  const backgroundColor = useTransform(x, [400, amount], ["#232323", "#fff"])
  const lineWidth = useTransform(x, [0, amount], ["0%", "100%"])
  const displayAfter = useTransform(x, [amount - 100, amount], ["0", "1"])
  const containerWitdh = useTransform(spring, [0, amount], ["100vw", "10vw"])
  const containerHeight = useTransform(spring, [0, amount], ["100vh", "6vh"])
  const buttonFadeIn = useTransform(x, [amount - 100, amount], [0, 1])
  const imagePadding = useTransform(x, [200, amount], ["2em", "0em"])
  const padding = useTransform(x, [200, amount], ["0", "0"])
  const navBarPadding = useTransform(x, [600, 1000], [isTablet ? "1em" : "20em", "0em"])
  const navBarPaddingLeft = useTransform(x, [600, 1000], [isTablet ? "1em" : "20em", "0em"])

  const [showButton, setShowButton] = React.useState()


  const [canonicalUrl, setCanonicalUrl] = React.useState("")

  // Get url from window
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setCanonicalUrl(window.location.href)
    }
  }, [])

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      console.log(window.scrollY)
      if (window.scrollY < 900) {
        setShowButton(false)
        setFadeOut(true)
      } else {
        setShowButton(true)
        setFadeOut(false)
      }
      // }
    }

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll)

    // Call handleScroll to set initial state based on current scroll position
    handleScroll()

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, []) // Empty dependency array ensures this runs once on mount

  useMotionValueEvent(buttonFadeIn, "change", latest => {
    if (latest > 0) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  })

  const nearestIndex = (
    currentPosition,
    sectionPositionArray,
    startIndex,
    endIndex,
  ) => {
    if (startIndex === endIndex) return startIndex
    else if (startIndex === endIndex - 1) {
      if (
        Math.abs(
          sectionPositionArray[startIndex].headerRef.current.offsetTop -
            currentPosition,
        ) <
        Math.abs(
          sectionPositionArray[endIndex].headerRef.current.offsetTop -
            currentPosition,
        )
      )
        return startIndex
      else return endIndex
    } else {
      var nextNearest = ~~((startIndex + endIndex) / 2)
      var a = Math.abs(
        sectionPositionArray[nextNearest].headerRef.current.offsetTop -
          currentPosition,
      )
      var b = Math.abs(
        sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop -
          currentPosition,
      )
      if (a < b) {
        return nearestIndex(
          currentPosition,
          sectionPositionArray,
          startIndex,
          nextNearest,
        )
      } else {
        return nearestIndex(
          currentPosition,
          sectionPositionArray,
          nextNearest,
          endIndex,
        )
      }
    }
  }

  React.useEffect(() => {
    if (location.pathname !== "/") return
    const handleScroll = e => {
      var index = nearestIndex(
        window.scrollY,
        navHeader,
        0,
        navHeader.length - 1,
      )
      setActiveIndex(index)
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {location.pathname === "/" ? (
        <>
          {isTablet ? (
            // MOBILE MENU FOR INDEX
            <header
              className="header_container"
              style={{
                // marginBottom: "1.45rem",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                backgroundColor: "inherit",
                clear: "both",
                zIndex: "9999",
              }}
            >
              {/* <HeaderMobile
                  fadeOut={fadeOut}
                  isHome={true}
                  activeIndex={activeIndex}
                  image={brentImage}
                /> */}
              <div
                style={
                  {
                    // margin: "0 5em 0 5em",
                  }
                }
              >
                <motion.nav
                  initial={{
                    marginLeft: isTablet ? "1em" : "20em",
                    marginRight: isTablet ? "1em" : "20em",
                  }}
                  style={{
                    marginLeft: navBarPaddingLeft,
                    marginRight: navBarPadding,
                  }}
                  id="navbar"
                  className="navbar-mobile-index"
                >
                  <ul
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      margin: "0",
                      marginLeft: "1em",
                      alignItems: "center",
                    }}
                  >
                    <motion.div
                      initial={{
                        width: "100vw",
                        height: "100vh",
                        minHeight: "50px",
                        // marginTop: "1em",
                        // marginBottom: "1em",
                      }}
                      style={{
                        width: containerWitdh,
                        height: containerHeight,
                        marginTop: padding,
                        marginBottom: padding,
                        display: "Flex",
                        minHeight: "90px",
                        minHeight: "70px",
                      }}
                    >
                      <motion.img
                        onClick={() =>
                          navigate(getLocalizedLink(locale, `/#intro`))
                        }
                        initial={{ paddingRight: "2em" }}
                        style={{
                          width: "100%",
                          height: "auto",
                          minWidth: "150px",
                          paddingRight: imagePadding,
                        }}
                        id="welcome-image"
                        className="welcome-image-static"
                        src={brent}
                        alt="brent logo"
                      />
                    </motion.div>
                  </ul>
                </motion.nav>
              </div>
              <motion.div
                id="line"
                initial={{ width: "0%" }}
                style={{
                  width: lineWidth,
                }}
              ></motion.div>
            </header>
          ) : (
            // DESKTOP MENU FOR INDEX
            <header
              className="header_container"
              style={{
                marginBottom: "1.45rem",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                backgroundColor: "inherit",
                clear: "both",
                zIndex: "9999",
              }}
            >
              <div>
                <motion.nav
                  initial={{
                    marginLeft: isTablet ? "1em" : "20em",
                    marginRight: isTablet ? "1em" : "20em",
                  }}
                  style={{
                    marginLeft: navBarPaddingLeft,
                    marginRight: navBarPadding,
                  }}
                  id="navbar"
                  className="navbar-desktop-index"
                >
                  <ul
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      margin: "0",
                      marginLeft: "1em",
                      alignItems: "center",
                    }}
                  >
                    <motion.div
                      initial={{
                        width: "100vw",
                        height: "100vh",
                        minHeight: "50px",
                        // marginTop: "1em",
                        // marginBottom: "1em",
                      }}
                      style={{
                        width: containerWitdh,
                        height: containerHeight,
                        marginTop: padding,
                        marginBottom: padding,
                        display: "Flex",
                        // minHeight: "90px",
                        minHeight: "70px",

                      }}
                    >
                      <motion.img
                        onClick={() =>
                          navigate(getLocalizedLink(locale, `/#intro`))
                        }
                        initial={{ paddingRight: "2em" }}
                        style={{
                          width: "100%",
                          height: "auto",
                          minWidth: "150px",
                          paddingRight: imagePadding,
                        }}
                        id="welcome-image"
                        className="welcome-image-static"
                        src={brent}
                        alt="brent logo"
                      />
                    </motion.div>
                    {showButton && (
                      <motion.div
                        id="rental-button"
                        initial={{
                          opacity: "0",
                          marginLeft: "auto",
                        }}
                        style={{
                          opacity: displayAfter,
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        {children}
                        <TestButton
                          isActive={activeIndex === 0}
                          text={"Home"}
                          link={"/#intro"}
                          location={location}
                        />
                        <TestButton
                          isActive={activeIndex === 1}
                          text={"Contact"}
                          link={"/#contact"}
                          location={location}
                          style={{ whiteSpace: "nowrap" }}
                        />
                        {/* <TestButton
                          location={location}
                          text={"Productions"}
                          link={"/productions/"}
                          style={{ whiteSpace: "nowrap" }}
                        /> */}
                        <TestButton
                          location={location}
                          text={"Support"}
                          link={"/support/"}
                          style={{ whiteSpace: "nowrap" }}
                        />
                        <TestButton
                          location={location}
                          text={"Equipment"}
                          link={"/rental/"}
                          style={{ whiteSpace: "nowrap" }}
                        />
                        <LocaleSelector
                          location={location}
                          index={1}
                        ></LocaleSelector>
                      </motion.div>
                    )}
                  </ul>
                </motion.nav>
              </div>
              <motion.div
                id="line"
                initial={{ width: "0%" }}
                style={{
                  width: lineWidth,
                }}
              ></motion.div>
            </header>
          )}
        </>
      ) : (
        // Regular Menu
        <>
          {isMobile || isTablet ? (
            // MOBILE MENU FOR ALL OTHER PAGES
            <HeaderMobile
              //  mobileOpen={mobileOpen}
              //  setMobileOpen={setMobileOpen}
              image={brentImage}
            />
          ) : (
            // DESKTOP MENU FOR ALL OTHER PAGES
            <header
              className="header_container"
              style={{
                marginBottom: "1.45rem",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                backgroundColor: "white",
                clear: "both",
                zIndex: "9999",
              }}
            >
              <div
                style={
                  {
                    // margin: "0 5em 0 5em",
                  }
                }
              >
                <nav id="navbar" className="navbar-desktop-all">
                  <ul
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      margin: "0",
                      marginLeft: "1em",
                      alignItems: "center",
                      // gap: "5vw",
                    }}
                  >
                    <div
                      style={{
                        width: "150px",
                        height: "6vh",
                        // marginTop: "1em",
                        // marginBottom: "1em",
                        display: "Flex",
                        // minHeight: "90px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        onClick={() => navigate(`/#intro`)}
                        style={{
                          width: "150px",
                          height: "auto",
                          minWidth: "150px",
                          paddingRight: "0em",
                        }}
                        id="welcome-image"
                        className="welcome-image-static"
                        src={brent}
                        alt="brent logo"
                      />
                    </div>
                    <div style={{ marginLeft: "10px", flexGrow: "1" }}>
                      <SearchBar
                        // handleDrawerToggle={handleDrawerToggle}
                        isMobile={false}
                      ></SearchBar>
                    </div>{" "}
                    <div
                      id="rental-button"
                      style={{
                        display: "flex",
                        gap: "5px",
                        // marginLeft: "auto",
                      }}
                    >
                      <TestButton
                        location={location}
                        text={"Home"}
                        link={"/#intro"}
                        style={{ whiteSpace: "nowrap" }}
                      />
                      <TestButton
                        location={location}
                        text={"Contact"}
                        link={"/#contact"}
                        style={{ whiteSpace: "nowrap" }}
                      />
                      {/* <TestButton
                        isActive={location.pathname.includes("/productions")}
                        text={"Productions"}
                        link={"/productions"}
                        location={location}
                        style={{ whiteSpace: "nowrap" }}
                      /> */}
                      <TestButton
                        isActive={location.pathname.includes("/support")}
                        text={"Support"}
                        link={"/support"}
                        location={location}
                        style={{ whiteSpace: "nowrap" }}
                      />
                      <TestButton
                        isActive={location.pathname.includes("/rental")}
                        text={"Equipment"}
                        link={"/rental"}
                        location={location}
                        style={{ whiteSpace: "nowrap" }}
                      />
                      {/* {children}
                <TestButton
                  isActive={location.pathname.includes("/productions")}
                  text={"Productions"}
                  link={"/productions/"}
                />
                <TestButton
                  isActive={location.pathname.includes("/rental")}
                  text={"Equipment"}
                  link={"/rental/"}
                /> */}

                      <LocaleSelector
                        location={location}
                        index={1}
                      ></LocaleSelector>
                    </div>
                  </ul>
                </nav>
              </div>
              <div
                id="line"
                style={{
                  width: "100%",
                }}
              ></div>
            </header>
          )}
        </>
      )}
    </>
  )
}

export default Header
