import React, { useState, useEffect, useContext } from "react"
import CookieConsent, {
  getCookieConsentValue,
  resetCookieConsentValue,
} from "react-cookie-consent"
import useTranslation from "../hooks/useTranslation"
import { AppContext } from "../context/appContext"
import { useLocation } from "@reach/router"
const COOKIE_NAME = "BrentWebsitecookieConsent"

// "title": "This site uses cookies",
//       "description": "This site uses cookies solely for Google Analytics, which helps us understand how visitors use our website and improve our services. No other tracking or advertising cookies are used.",
//       "acceptButton": "Accept Analytics Cookies"

// Function to load Google Analytics
export const loadGoogleAnalytics = () => {
  // Create the script tag to load Google Analytics
  const script1 = document.createElement("script")
  script1.src = "https://www.googletagmanager.com/gtag/js?id=G-32W1T09F3W"
  script1.async = true
  document.body.appendChild(script1)

  // Ensure dataLayer is defined before using it
  script1.onload = () => {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      window.dataLayer.push(arguments)
    }

    // Initialize Google Analytics with the config ID
    gtag("js", new Date())
    gtag("config", "G-32W1T09F3W")
  }
}

// Main cookie consent banner component
const CustomCookieConsent = () => {
  const { cookieBanner } = useTranslation()
  const { fadeOut } = useContext(AppContext)
  const location = useLocation()
  const isHome = location.pathname === "/"
  return (
    <CookieConsent
      location="bottom"
      buttonText={cookieBanner?.acceptButton}
      cookieName={COOKIE_NAME}
      style={{
        background: "#2B373B",
        alignItems: "flex-end",
        transition: "opacity 0.5s ease",
        opacity: isHome && fadeOut ? 0 : 1,
        zIndex: 99999999,
      }}
      buttonStyle={{
        color: "black",
        background: "#EB5931",
        fontSize: "15px",
        // padding: "2px 20px",
        // margin: "2px 2px",
      }}
      onAccept={() => loadGoogleAnalytics()}
      expires={365}
    >
      <strong>{cookieBanner?.title}</strong> {cookieBanner?.description}
    </CookieConsent>
  )
}

// Consent removal component
const ConsentRemoval = () => {
  const { cookieBanner } = useTranslation()

  const [isCookieSet, setIsCookieSet] = useState(false)

  useEffect(() => {
    // Check if the consent cookie is set
    setIsCookieSet(getCookieConsentValue(COOKIE_NAME) === "true")
  }, [])

  const handleWithdrawConsent = () => {
    resetCookieConsentValue(COOKIE_NAME)
    setIsCookieSet(false) // Hide the button after withdrawal
    window.location.reload() // Reload the page to reflect changes
  }

  // Only render the button if the consent cookie is set
  if (!isCookieSet) return null

  return (
    <div style={{ fontSize: "0.9em" }} onClick={handleWithdrawConsent}>
      {cookieBanner?.removeButton}
    </div>
  )
}

export { CustomCookieConsent, ConsentRemoval }
