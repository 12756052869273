/*global google*/
import * as React from "react"
import { navigate } from "gatsby"
import * as homeFooterStyles from "./homeFooter.module.css"
import { useLocale } from "../../hooks/useLocale"
import LocaleSelector from "../localeSelector/localeSelector"
import { useLocation } from "@reach/router"
import useTranslation from "../../hooks/useTranslation"
import { getLocalizedLink } from "../../utils/getLocalizedLink"
import { ConsentRemoval } from "../../utils/cookieBanner"

const HomeFooter = ({ sticky }) => {
  const location = useLocation()
  const { locale } = useLocale()
  const { websiteText } = useTranslation()


  const actionLink = type => {
    if (type === "mail") {
      var mail = document.createElement("a")
      mail.href = "mailto:booking@brent.as"
      mail.click()
    } else if (type === "call") {
      var call = document.createElement("a")
      call.href = "tel:+47 96 90 80 00"
      call.click()
    } else if (type === "map") {
      var map = document.createElement("a")
      map.target = "_blank"
      map.href =
        "https://www.google.com/maps/place/Brent/@59.9527997,10.7638016,16.95z/data=!4m10!1m2!2m1!1sbrent+as!3m6!1s0x46416f3fd108d9d7:0xb9d61b4e1d7ad526!8m2!3d59.9529673!4d10.7658143!15sCghicmVudCBhc5IBJWF1ZGlvX3Zpc3VhbF9lcXVpcG1lbnRfcmVudGFsX3NlcnZpY2XgAQA!16s%2Fg%2F11ld43qy1b?entry=ttu"
      map.click()
    }
  }

  let footerStyle = {}

  if (sticky) {
    footerStyle = {
      position: "relative",
      bottom: "0px",
      width: "100%",
      overflowX: "hidden",
    }
  } else {
    footerStyle = {
      position: "relative",
      padding: "0",
      overflowX: "hidden",
      paddingBottom: "env(safe-area-inset-bottom) + 50px !important",
    }
  }

  React.useEffect(
    (prevProps, prevState) => {
      // console.log(prevProps, prevState)
    },
    [websiteText],
  )

  return (
    <footer style={footerStyle}>
      <div className={homeFooterStyles.contactDetailsFooter}>
        <div
        id={homeFooterStyles.footerId} 
          className={homeFooterStyles.footerContainer}
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            maxWidth: "870px",
            color: "white",
          }}
        >
          <div
            className={homeFooterStyles.footerWrapOuter}
            style={{
              display: "flex",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div
              className={homeFooterStyles.footerWrap}
              onClick={() => actionLink("mail")}
            >
              <div
                style={{
                  color: "white",
                }}
                className={homeFooterStyles.footerButton}
              >
                BOOKING @ BRENT.AS
              </div>
            </div>
            <div
              style={{
                display: "inline-block",
                position: "relative",
                paddingBottom: "3px",
                marginRight: "10px",
              }}
              className={homeFooterStyles.footerWrap}
              onClick={() => actionLink("map")}
            >
              <div
                style={{
                  color: "white",
                }}
                className={homeFooterStyles.footerButton}
              >
                OSLO FILM STUDIOS
              </div>
            </div>

            <div
              style={{
                display: "inline-block",
                position: "relative",
                paddingBottom: "3px",
                marginRight: "10px",
              }}
              className={homeFooterStyles.footerWrap}
              onClick={() => actionLink("map")}
            >
              <div
                style={{
                  color: "white",
                }}
                className={homeFooterStyles.footerButton}
              >
                GJERDRUMS VEI 6, 0484 OSLO
              </div>
            </div>
          </div>
          <div
            className={homeFooterStyles.footerWrapOuter}
            style={{
              display: "flex",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <div
              className={homeFooterStyles.footerWrap}
              
              onClick={() => navigate(getLocalizedLink(locale, `/support/`))}
            >
              <div
                style={{
                  color: "white",
                }}
                className={homeFooterStyles.footerButton}
              >
                {websiteText.homeFooterSupport ? websiteText.homeFooterSupport.toUpperCase() : ""}
                {/* SUPPORT */}
              </div>
            </div>
            <div
              className={homeFooterStyles.footerWrap}
              onClick={() => navigate(getLocalizedLink(locale, `/terms/`))}

            >
              <div
                style={{
                  color: "white",
                }}
                className={homeFooterStyles.footerButton}
              >
                {websiteText.homeFooterTerms ? websiteText.homeFooterTerms.toUpperCase() : ""}
              </div>
            </div>
            <div
              className={homeFooterStyles.footerWrap}
              // onClick={() => navigate(`/${locale}/support/`)}
            >
              <div
                style={{
                  color: "white",
                }}
                // className={homeFooterStyles.footerButton}
              >
                <LocaleSelector variant="test" location={location} style={{justifyContent: "flex-end", paddingRight: "0"}} />
                <ConsentRemoval />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default HomeFooter
