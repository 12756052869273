import React, { useContext } from "react"
import { AppContext } from "../../context/appContext"
import {useLocation} from "@reach/router"
import * as styles from "./hamburger.module.css"

const Hamburger = () => {
  const location = useLocation();
  const { mobileOpen, setMobileOpen } = useContext(AppContext);

  const handleOpenMenu = () => {
    setMobileOpen(!mobileOpen)
  }



  return (
    <div           className={styles.container}
>
      {location.pathname.includes("/rental") && (
        <div
          onClick={() => handleOpenMenu()}
          className={styles.hamburger}
        >
          {/* {mobileOpen ? "Close" : "Open"} */}
          <div
            className={`${styles.navIcon3} ${mobileOpen ? styles.open : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Hamburger
