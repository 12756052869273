// src/hooks/useLocale.js
import React, { createContext, useState, useContext, useEffect } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import locales from "../../i18n/locales"

const LocaleContext = createContext()

const LocaleProvider = ({ children }) => {
  const { pathname } = useLocation()

  // Function to extract language from the URL
  const getLangFromPath = (path) => {
    const segments = path.split("/").filter(Boolean); // Removes empty segments
    const potentialLocale = segments[0];
    return potentialLocale === 'no' ? 'no' : 'en'; // Return 'no' if it's Norwegian, else 'en'
  };

  // Determine the initial locale from the URL, browser's setting, or use the default
  let initialLang = ""
  if (typeof window !== "undefined") {
    initialLang =
      getLangFromPath(pathname) ||
      (navigator.language || navigator.userLanguage).split("-")[0] ||
      Object.keys(locales).find(lang => locales[lang].default)
  }

  const [locale, setLocale] = useState(initialLang)

  useEffect(() => {
    const currentLang = getLangFromPath(pathname)
    if (currentLang && currentLang !== locale) {
      setLocale(currentLang)
    }
  }, [pathname, locale])

  const changeLocale = (lang, url = { pathname }) => {
    const currentPathLocale = getLangFromPath(url.pathname)
    let newPath = url.pathname

    if (lang === 'en') {
      // For English, remove the locale part of the URL if it's 'no'
      newPath = currentPathLocale === 'no' ? url.pathname.replace(`/no`, '') : url.pathname
    } else if (lang === 'no') {
      // For Norwegian, add '/no' if not already present
      newPath = currentPathLocale === 'en' ? `/no${url.pathname}` : url.pathname
    }

    setLocale(lang)
    navigate(newPath)
  }

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}

const useLocale = () => {
  const context = useContext(LocaleContext)
  if (!context) {
    throw new Error("useLocale must be used within a LocaleProvider")
  }
  return context
}

export { LocaleProvider, useLocale }