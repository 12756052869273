import React, { useEffect, useState, useMemo } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Loading from "../loading/loading"
import { useTheme, List, ListItemButton, ListItemText } from "@mui/material"

import { useLocale } from "../../hooks/useLocale"
import useTranslation from "../../hooks/useTranslation"
import MenuItem from "./menuItem"

import { getLocalizedLink } from "../../utils/getLocalizedLink"

const Menu = ({ setMenu, menu }) => {
  const theme = useTheme()
  const { locale } = useLocale()
  const { translations } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  const graphiqlData = useStaticQuery(graphql`
    query TEST {
      allBrentRentalItem {
        nodes {
          displayname
          id
          image
          name
          rentmanId
          shop_description_long
          shop_description_short
          title
          in_shop
          folder
          urlPath
          pageLinkBrent
          custom {
            custom_13
          }
        }
      }
      allBrentRentalFolder {
        nodes {
          displayname
          id
          urlPath
          name
          rentmanId
          title
          path
          itemtype
          order
          pageLinkBrent
          menuParentBrent
        }
      }
    }
  `)

  const hiddenItems = useMemo(() => [251, 45, 46, 47], [])

  const sortedMenu = useMemo(() => {
    const completeMenuArr = []

    const filteredRentalData = graphiqlData.allBrentRentalItem.nodes.filter(
      item => item.in_shop,
    )
    filteredRentalData.forEach(item => {
      item.parentFolderId = parseInt(item.folder.split("/").slice(-1))
      completeMenuArr.push(item)
    })

    const filteredFolderData = graphiqlData.allBrentRentalFolder.nodes.filter(
      item => {
        return (
          !hiddenItems.includes(item.rentmanId) &&
          !item.displayname.startsWith("import-") &&
          item.itemtype !== "contact" &&
          item.itemtype !== "vehicle" &&
          item.itemtype !== "user"
        )
      },
    )

    filteredFolderData.forEach(item => {
      if (item.menuParentBrent != null) {
        item.parentFolderId = parseInt(
          item.menuParentBrent.split("/").slice(-1),
        )
      } else {
        item.parentFolderId = null
      }
      completeMenuArr.push(item)
    })

    const sort = arr => arr.sort((a, b) => a.order - b.order)

    const menuSort = (function (data, root) {
      const t = {}
      data.forEach(o => {
        Object.assign((t[o.rentmanId] = t[o.rentmanId] || {}), o)
        ;((t[o.parentFolderId] ??= {}).children ??= []).push(t[o.rentmanId])
      })
      return t[root]?.children ?? []
    })(completeMenuArr, null)

    const finalMenu = menuSort.reduce((r, { children = [], ...o }) => {
      const sortedChildren = children.sort((a, b) => {
        if (a.children && !b.children) return 1
        if (!a.children && b.children) return -1
        return a.displayname.localeCompare(b.displayname)
      })
      if (o.in_shop || sortedChildren.length)
        r.push({ ...o, children: sortedChildren })
      return r
    }, [])

    return sort(finalMenu)
  }, [graphiqlData, hiddenItems])

  useEffect(() => {
    if (sortedMenu.length) {
      setMenu(sortedMenu) // Layout menu setter
      setIsLoading(false)
    }
  }, [sortedMenu, setMenu])

  const handleClick = (e, item) => {
    navigate(getLocalizedLink(locale, `/rental${item.urlPath}`))
  }

  const listItemStyles = {
    alignItems: "flex-end",
    borderBottom: "1px solid #dbdbdb",
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.default.selected,
    },
    "&& .MuiTouchRipple-child": {
      backgroundColor: theme.palette.secondary.main,
    },
    ":hover": {
      backgroundColor: "white",
      color: `${theme.palette.secondary.main} !important`,
    },
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <nav>
      <List>
        <li>
          <ListItemButton
            color="secondary"
            sx={listItemStyles}
            onClick={() => navigate(getLocalizedLink(locale, `/rental/`))}
          >
            <ListItemText
              sx={{ marginTop: "0px", marginBottom: "0px" }}
              primary={translations["All Equipment"]}
            />
          </ListItemButton>
        </li>
        {menu &&
          menu.map(
            item =>
              item.rentmanId !== 320 && (
                <MenuItem
                  key={item.id}
                  // onClick={e => handleClick(e, item)}
                  item={item}
                />
              ),
          )}
      </List>
    </nav>
  )
}

export default Menu
