import React, {useState} from "react"
import * as buttonStyles from "./testButton.module.css"
import { navigate as gatsbyNavigate } from "gatsby"
import { useLocation } from "@reach/router"

const TestButton = ({
  text,
  link,
  style,
  icon: Icon,
  isActive,
  renderIcon,
}) => {
  const location = useLocation()
  const [isHover, setIsHover] = useState(false);

  const handleClick = async () => {
    if(!link) return
    const isHashLink = link.includes("#")
    const [pathname, hash] = link.split("#")

    if (location.pathname === "/" && isHashLink) {
      // On the home page, directly scroll to the hash ID without navigation
      if (hash) {
        document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      // Navigate to the new page
      await gatsbyNavigate(pathname)

      if (isHashLink && hash) {
        // Use MutationObserver to scroll when the element appears
        const observer = new MutationObserver(mutationsList => {
          const targetElement = document.getElementById(hash)
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" })
            observer.disconnect() // Stop observing after scrolling
          }
        })

        // Start observing
        observer.observe(document.body, { childList: true, subtree: true })
      }
    }
  }

  return (
    <button
      onClick={handleClick}
      style={style}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`${buttonStyles.button} ${isActive ? buttonStyles.activeBtn : ""}`}
    >
      {renderIcon ? renderIcon(isHover) : null}

      {/* {Icon && (
        <Icon
          style={{ color: isActive ? "#EB5931" : "white", fontSize: "large" }}
        />
      )}      */}
      <div className={buttonStyles.buttonText}>{text}</div>
    </button>
  )
}

export default TestButton
