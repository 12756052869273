import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import NoResultsBoundary from './noResultBoundary';
import CustomHits from './customHits';
import * as searchStyles from "./search.module.css";

function CustomSearchBox() {
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  function setQuery(newQuery) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  function clearSearchInput() {
    setInputValue('');
    refine('');
  }

  useEffect(() => {
    if (query !== "") {
      setOpen(true);
      inputRef.current.classList.add(searchStyles.activeSearch);
    } else {
      setOpen(false);
      inputRef.current.classList.remove(searchStyles.activeSearch);
    }
  }, [query]);

  useEffect(() => {
   if (isOpen === false) {
    setInputValue("");
    refine("")
   }
  }, [isOpen]);


  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
        removeClass();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const removeClass = () => {
    inputRef.current.classList.remove(searchStyles.activeSearch);
  };

  return (
    <div ref={containerRef} className={searchStyles.searchContainer}>
      <input
        className={`${searchStyles.aisSearchBoxInput} ${isOpen ? searchStyles.activeSearch : searchStyles.rounded}`}
        ref={inputRef}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        placeholder="Search for products"
        spellCheck={false}
        maxLength={512}
        type="search"
        value={inputValue}
        onChange={(event) => {
          setQuery(event.currentTarget.value);
        }}
      />
      {isOpen && (
        <div className={searchStyles.searchHitContainer}>
          <NoResultsBoundary>
            <CustomHits
              closeSearchResults={() => setOpen(false)}
              clearSearchInput={clearSearchInput} // Pass the function here
            />
          </NoResultsBoundary>
        </div>
      )}
    </div>
  );
}

export default CustomSearchBox;
