// extracted by mini-css-extract-plugin
export var bottom = "homeFooter-module--bottom--5dd36";
export var callToActionContainer = "homeFooter-module--call-to-action-container--83f42";
export var contactButton = "homeFooter-module--contact-button--9484b";
export var contactDetailsFooter = "homeFooter-module--contact-details-footer--6598c";
export var contactWrap = "homeFooter-module--contact-wrap--dfc0e";
export var footer = "homeFooter-module--footer--9a37f";
export var footerButton = "homeFooter-module--footer-button--5430f";
export var footerContainer = "homeFooter-module--footer-container--7449c";
export var footerId = "homeFooter-module--footerId--0f4a1";
export var footerImage = "homeFooter-module--footer-image--b57b6";
export var footerWrap = "homeFooter-module--footer-wrap--40a77";
export var footerWrapOuter = "homeFooter-module--footer-wrap-outer--01ab5";
export var infoContact = "homeFooter-module--info-contact--03400";
export var top = "homeFooter-module--top--3068a";
export var xColophon = "homeFooter-module--x-colophon--d53a2";