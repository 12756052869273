import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/appContext";
import { useLocation } from "@reach/router";
import LocaleSelector from "../localeSelector/localeSelector";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as headerMobileStyles from "./headerMobile.module.css";
import Hamburger from "../hamburger/hamburger";
import TestButton from "../button/testButton";

const HeaderMobile = React.memo(() => {
  const { mobileOpen, setMobileOpen, fadeOut, activeIndex, isTablet, isMobile } = useContext(AppContext);
  const location = useLocation();
  const isHome = location.pathname === "/";
  const navbarRef = useRef(null);
  const iconRef = useRef(null);
  let lastScrollY = 0;
  const [scrollUp, setScrollUp] = useState(false);

  const handleScroll = () => {
    if (typeof window !== "undefined") {
      const currentScrollY = window.scrollY;
      const navbar = navbarRef.current;
      const iconContainer = iconRef.current;

      if (navbar) {
        if (currentScrollY > lastScrollY && currentScrollY > 50) {
          // Scrolling down
          setScrollUp(false);
          navbar.style.transform = `translateY(${navbar.offsetHeight -10}px)`; // Hide navbar
          iconContainer.style.top = `-20px`

        } else if (currentScrollY < lastScrollY) {
          // Scrolling up
          setScrollUp(true);
          iconContainer.style.top = `-20px`
          navbar.style.transform = `translateY(0px)`; // Show navbar

        }

        // Always update the lastScrollY
        lastScrollY = currentScrollY;
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const navbar = navbarRef.current;

      // Set initial navbar position if home
      if (navbar) {
        navbar.style.transform = isHome
          ? `translateY(${navbar.offsetHeight -10}px)` // Hide by default if on home page
          : `translateY(0)`; // Else show it
      }

      window.addEventListener("scroll", handleScroll);
      window.addEventListener("touchmove", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("touchmove", handleScroll);
      };
    }
  }, [isHome]);

  return (
    <>
      {(isTablet || isMobile) && (
        <nav
          ref={navbarRef}
          id="navbar"
          className={`${headerMobileStyles.nav} ${isHome && fadeOut ? headerMobileStyles.fadeout : ""}`}
        >
          <div ref={iconRef} className={headerMobileStyles.arrowContainer}>
            {scrollUp ? (
              <KeyboardArrowDownIcon className={headerMobileStyles.arrow} sx={{ marginBottom: "-6px" }} />
            ) : (
              <KeyboardArrowUpIcon className={headerMobileStyles.arrow} sx={{ marginBottom: "-6px" }} />
            )}
            <Hamburger />
          </div>
          <ul
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              margin: "0",
              backgroundColor: "white",
            }}
          >
            <div className={headerMobileStyles.arrowUp}></div>
            <div className={headerMobileStyles.arrowUp2}></div>
            <div
              style={{
                display: "flex",
                marginBottom: location.pathname.startsWith("/rental/")
                  ? "10px"
                  : "0px",
                marginTop: "10px",
                backgroundColor: "white",
                width: "100%",
                justifyContent: "center",
                justifyContent: "space-evenly",
              }}
            >
              <TestButton
                isActive={isHome && activeIndex === 0}
                location={location}
                text={"Home"}
                link={"/#intro"}
                style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              />
              <TestButton
                isActive={isHome && activeIndex === 1}
                location={location}
                text={"Contact"}
                link={"/#contact"}
                style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              />
              <TestButton
                isActive={location.pathname.includes("/support")}
                text={"Support"}
                link={"/support"}
                location={location}
                style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              />
              {/* <TestButton
                isActive={location.pathname.includes("/productions")}
                text={"Articles"}
                link={"/productions"}
                location={location}
                style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              /> */}
              <TestButton
                isActive={location.pathname.includes("/rental")}
                text={"Equipment"}
                link={"/rental"}
                location={location}
                style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              />
              <LocaleSelector location={location} index={1}></LocaleSelector>
            </div>
          </ul>
        </nav>
      )}
    </>
  );
});

export default HeaderMobile;